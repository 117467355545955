// App.js
import React, { useEffect } from "react";

import "./css/landing.css";

const MarianoMelgarLading = () => {
	useEffect(() => {
		// Cambiar el título de la pestaña
		const originalTitle = document.title;
		document.title = "Serenazgo - Mariano Melgar";

		// Cambiar el favicon
		const favicon = document.querySelector('link[rel="icon"]');
		const originalFavicon = favicon.href;
		favicon.href = "/favicon_3.ico";
		// Revertir cambios al desmontar el componente
		return () => {
			document.title = originalTitle;
			favicon.href = originalFavicon;
		};
	}, []);
	return (
		<div className="landingbody">
			<div className="landingcontainer">
				<div className="landingcard">
					<h2 className="landingtitle">Serenazgo - Mariano Melgar</h2>
					<p className="landingdescription">¡Habla bien de tu distrito!</p>
					<img
						src="/images/web/logo_smmelgar.png"
						alt="Logo Serenazgo"
						className="landinglogo"
					/>

					<div className="buttons">
						<a
							href="https://play.google.com/store/apps/details?id=com.itc.smmelgar"
							className="store-button google">
							<img
								src="/images/web/googleplay.png"
								//srcSet={`${item.img}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
								alt="Google Play Sosmuni"
								loading="lazy"
							/>
						</a>
						<a
							href="https://apps.apple.com/pe/app/serenazgo-mariano-melgar/id6740829094"
							className="store-button apple">
							<img
								src="/images/web/appstore.png"
								//srcSet={`${item.img}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
								alt="Google Play Sosmuni"
								loading="lazy"
							/>
						</a>
					</div>
					<p className="footer-text">
						Parte de la familia de SosMuni y Desarrollado por ITC
					</p>
					<a href="/" className="more-info">
						Más información →
					</a>
				</div>
			</div>
		</div>
	);
};

export default MarianoMelgarLading;

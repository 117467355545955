import { makeStyles } from "@mui/styles";
import React from "react";
import AppBarComponent from "./AppBar";
import { Box } from "@mui/material";
import FooterComponent from "./footer";
import { useLocation } from "react-router-dom";

const useStyles = makeStyles((theme) => {
	return {
		toolbar: theme.mixins.toolbar,
	};
});

function Layout({ children }) {
	const location = useLocation();

	// Rutas donde no se muestran header ni footer
	const hideHeaderFooter = [
		"/mariano_melgar",
		"/cerro_colorado",
		"/mariano_melgar/privacidad",
	];

	const shouldHide = hideHeaderFooter.includes(location.pathname);
	const classes = useStyles();
	return (
		<div>
			{/* Renderiza el AppBar solo si shouldHide es falso */}
			{!shouldHide && <AppBarComponent />}
			<Box>
				{!shouldHide && <div className={classes.toolbar}> </div>}
				{children}
			</Box>
			{/* Renderiza el Footer solo si shouldHide es falso */}
			{!shouldHide && <FooterComponent />}
		</div>
	);
}

export default Layout;

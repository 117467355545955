// App.js
import React, { useEffect } from "react";

import "./css/landingcc.css";

const CerroColoradoLading = () => {
	useEffect(() => {
		// Cambiar el título de la pestaña
		const originalTitle = document.title;
		document.title = "SOS Cerro Colorado";

		// Cambiar el favicon
		const favicon = document.querySelector('link[rel="icon"]');
		const originalFavicon = favicon.href;
		favicon.href = "/favicon_4.ico";
		// Revertir cambios al desmontar el componente
		return () => {
			document.title = originalTitle;
			favicon.href = originalFavicon;
		};
	}, []);
	return (
		<div className="landingccbody">
			<div className="landingcccontainer">
				<div className="landingcccard">
					<h2 className="landingcctitle">SOS Cerro Colorado</h2>
					<p className="landingccdescription">
						¡Con seguridad sembramos progreso!
					</p>
					<img
						src="/images/web/logo_sccolorado.png"
						alt="Logo Cerro Colorado"
						className="landingcclogo"
					/>

					<div className="buttons">
						<a
							href="https://play.google.com/store/apps/details?id=com.itc.sccolorado"
							className="store-button google">
							<img
								src="/images/web/googleplay.png"
								//srcSet={`${item.img}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
								alt="Google Play"
								loading="lazy"
							/>
						</a>
						<a
							href="https://play.google.com/store/apps/details?id=com.itc.sccolorado"
							className="store-button apple">
							<img
								src="/images/web/appstore.png"
								//srcSet={`${item.img}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
								alt="App Store"
								loading="lazy"
							/>
						</a>
					</div>
					<p className="footer-text">
						Parte de la familia de SosMuni y Desarrollado por ITC
					</p>
					<a href="/" className="more-info">
						Más información →
					</a>
				</div>
			</div>
		</div>
	);
};

export default CerroColoradoLading;
